
import { Component } from 'vue-property-decorator';
import { RouteConfig } from 'vue-router';
import SubModule from '@/components/SubModule.vue';
import { IMenuItem } from '@/components/Menu.vue';
import routes from './routes';
import store from './store';
import { EntityStoreGetters } from '@/base';
import { getFullName, UserType } from '@/modules/user';
import { store as mainStore, Getters } from '@/store';
import { ReceiverOption } from '../json';

@Component
export default class OnSiteTranslation extends SubModule {
  private get activeItem(): typeof store[EntityStoreGetters.ACTIVE_ITEM] {
    return store[EntityStoreGetters.ACTIVE_ITEM];
  }

  protected _getRoutes(): RouteConfig[] {
    return routes;
  }

  protected _getDynamicMenuItems(): IMenuItem[] {
    const activeItem = this.activeItem;
    if (activeItem) {
      if (activeItem.$isCreated()) {
        const edit = {
          title: `${activeItem.$original.contractNo}`,
          icon: `$vuetify.icons.edit`,
          route: `/onsitetranslation/${activeItem.id}`,
        };

        const fileAttachmentCollection = {
          title: this.$t('fileAttachmentCollection.title').toString(),
          icon: '$vuetify.icons.attachment',
          route: `/onsitetranslation/${activeItem.id}/fileAttachmentCollection`,
        };

        const invoiceContact = {
          title: this.$t('invoiceContact.title').toString(),
          icon: `$vuetify.icons.contact`,
          route: `/onsitetranslation/${activeItem.id}/invoicecontact`,
        };

        const creditContact = {
          title: this.$t('creditContact.title').toString(),
          icon: `$vuetify.icons.contact`,
          route: `/onsitetranslation/${activeItem.id}/creditcontact`,
        };

        const operationContact = {
          title: this.$t('operationContact.title').toString(),
          icon: `$vuetify.icons.contact`,
          route: `/onsitetranslation/${activeItem.id}/operationcontact`,
        };

        if (this.isAllowForAdminsAndOfficeWorkers()) {
          const items: IMenuItem[] = [];
          items.push(edit);
          items.push(fileAttachmentCollection);
          items.push(operationContact);
          if (activeItem.invoiceReceiverOption === ReceiverOption.ALTERNATIVE_ADDRESS) {
            items.push(invoiceContact);
          }
          if (activeItem.creditReceiverOption === ReceiverOption.ALTERNATIVE_ADDRESS) {
            items.push(creditContact);
          }
          return items;
        }
        return [edit, fileAttachmentCollection];
      } else {
        return [
          {
            title: this.$t('form.titleNew').toString(),
            icon: `$vuetify.icons.add`,
            route: `/onsitetranslation/new`,
          },
        ];
      }
    }
    return [];
  }

  private isAllowForAdminsAndOfficeWorkers(): boolean {
    const currentUserType = mainStore[Getters.CURRENT_USER_LOGIN]!.userType;
    return currentUserType === UserType.OFFICE_WORKER || currentUserType === UserType.ADMIN;
  }
}
